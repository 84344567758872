import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../environments/environment';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { TaasToastrComponent, TaasHiddenVersionInfoComponent, TaasButtonComponent } from 'utimaco-common-ui-angular';
import appPackageJson from '../../package.json';
import commonUiPackageJson from 'utimaco-common-ui-angular/package.json';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatButtonModule, LanguageSelectorComponent, TaasToastrComponent, TaasHiddenVersionInfoComponent, TaasButtonComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  homeLink: string = environment.homeLink;
  btnlabelLogIn: string = $localize`Log-in`;

  commonUiLibVersion: string = commonUiPackageJson.version;
  appVersion: string = appPackageJson.version;

  goToLoginPage() {
    window.open(environment.loginLink, '_blank');
  }
}
