<div id="registration-form-component" class="gradientBackground">
    <lib-taas-modal-loading-spinner [content]="loadingText" width="300px" height="150px" [spinnerVisible]="formSubmissionInProgress"/>

    <div id="registration-form-container">
        <div i18n="registrationForm: title" class="registrationTitle">Registration</div>
        <h3 i18n="registrationForm: subtitle" class="registrationSubTitle">Achieve digital trust for your business
            within few clicks.</h3>

        <div id="registration-form">
            @if(submissionError){
            <div id="submission-error" data-testid="registrationform.submissionerror">
                <div i18n="registrationForm: form submission error message">
                    We are sorry, a problem occured while trying to submit your registration details.<br />Please try
                    again or or reach out to us via our 
                    <a href="https://utimaco.com/company/contact-us" target="_blank">
                        contact form
                    </a>.
                </div>
                @if(submissionErrorDetails){
                <div class="error-details" data-testid="registrationform.submissionerrordetails" [innerHTML]="submissionErrorDetails"></div>
                }
            </div>
            }

            <form (ngSubmit)="onSubmit()" method="POST" id="registrationForm" data-testid="registrationform">
                <p i18n="registrationForm: form intro text">Please fill out the form to achieve digital trust for your
                    business. Driven by yourself –
                    powered by
                    Utimaco.</p>
                <div class="row" style="margin-top: 30px;">
                    <div class="col">
                        <mat-label i18n="registrationForm: firstname field label">First-Name <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="firstname-input" name="FirstName" [formControl]="firstName"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="40" data-testid="registrationform.input.firstname">
                            @if (firstName.invalid) {
                            <mat-error i18n="registrationForm: error message firstname required" data-testid="registrationform.error.firstname">First-Name is
                                required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: lastname field label">Last-Name <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="lastname-input" name="LastName" [formControl]="lastName"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="40"  data-testid="registrationform.input.lastname">
                            @if (lastName.invalid) {
                            <mat-error i18n="registrationForm: error message lastname required" data-testid="registrationform.error.lastname">Last-Name is
                                required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-label i18n="registrationForm: Job Title field label">Job Title</mat-label>
                        <mat-form-field appearance="outline">
                            <input id="jobtitle-input" type="text" name="JobTitle" [formControl]="jobTitle"
                                (blur)="updateFormErrorStatus()" matInput maxlength="90" data-testid="registrationform.input.jobtitle">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-label i18n="registrationForm: Email field label">Email <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="email-input" type="email" name="Email" [formControl]="email"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="80" data-testid="registrationform.input.email">
                            @if (email.invalid) {
                            <mat-error i18n="registrationForm: error message valid email required" data-testid="registrationform.error.email">Valid Email address
                                is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: email confirmation field label">Confirm Email
                            <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="email-confirmation-input" type="email" [formControl]="emailConfirmation"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="80" data-testid="registrationform.input.emailconfirmation">
                            @if (emailConfirmation.invalid) {
                            <mat-error i18n="registrationForm: error message emails must match" data-testid="registrationform.error.emailconfirmation">Email addresses must
                                match</mat-error>
                            }
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <mat-label i18n="registrationForm: phone country code selection label">Country Code</mat-label>
                        <mat-form-field>
                            <mat-select id="country-code-input" [formControl]="phoneCountryCode"
                                (blur)="updateFormErrorStatus()" appearance="outline" placeholder="-- Select --"
                                i18n-placeholder="-- Select --" data-testid="registrationform.select.phonecountrycode">
                                @for(countryCodeObject of countryCodeList; track 'countrycode_' + countryCodeObject.countryNumber + '_' + countryCodeObject.displayName){
                                <mat-option value="{{countryCodeObject.countryNumber}}">
                                    {{ getLocalCountryName(countryCodeObject) }} (+{{countryCodeObject.countryNumber}})
                                </mat-option>
                                }
                            </mat-select>
                            @if (phoneCountryCode.invalid) {
                            <mat-error i18n="registrationForm: error message country code required" data-testid="registrationform.error.phonecountrycode">Country Code is
                                required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: phone number label">Phone Number</mat-label>
                        <mat-form-field appearance="outline">
                            <input id="phone-number-input" [formControl]="phoneNumber" (blur)="updateFormErrorStatus()"
                                matInput maxlength="16" data-testid="registrationform.input.phonenumber">
                            @if (phoneNumber.invalid) {
                            <mat-error i18n="registrationForm: error message valid phone number required" data-testid="registrationform.error.phonenumber">A valid phone
                                number is required. Only numbers are
                                allowed.</mat-error>
                            }
                        </mat-form-field>
                        <input type="hidden" name="Phone" [formControl]="phone" maxlength="40" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-label i18n="registrationForm: company label">Company <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="company-input" [formControl]="company" name="Company"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="100" data-testid="registrationform.input.companyname">
                            @if (company.invalid) {
                            <mat-error i18n="registrationForm: error message company required" data-testid="registrationform.error.companyname">Company is
                                required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: street address label">Street Address <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="street-input" [formControl]="address" name="Address"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="100" data-testid="registrationform.input.companyaddress">
                            @if (address.invalid) {
                            <mat-error i18n="registrationForm: error message valid street address required" data-testid="registrationform.error.companyaddress">Street
                                Address is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-label i18n="registrationForm: zip code label">ZIP</mat-label>
                        <mat-form-field appearance="outline">
                            <input id="zip-input" [formControl]="zip" name="ZIP" (blur)="updateFormErrorStatus()"
                                matInput maxlength="15" data-testid="registrationform.input.companyzipcode">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: label for City">City <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="city-input" [formControl]="city" name="City" (blur)="updateFormErrorStatus()"
                                matInput required maxlength="40" data-testid="registrationform.input.companycity">
                            @if (city.invalid) {
                            <mat-error i18n="registrationForm: error message valid city required" data-testid="registrationform.error.companycity">City is
                                required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: label for Country selection">Country <sup>*</sup></mat-label>
                        <mat-form-field>
                            <mat-select id="country-select" [formControl]="country" (blur)="updateFormErrorStatus()"
                                appearance="outline" placeholder="-- Select --" i18n-placeholder="-- Select --"
                                required data-testid="registrationform.select.companycountry">
                                @for(countryCodeObject of countryCodeList; track 'country_' + countryCodeObject.countryNumber + '_' + countryCodeObject.displayName){
                                <mat-option value="{{countryCodeObject.countryCode}}">
                                    {{getLocalCountryName(countryCodeObject) }}
                                </mat-option>
                                }

                            </mat-select>
                            <input type="hidden" name="Country" [formControl]="countryHidden" />
                            @if (country.invalid) {
                            <mat-error i18n="registrationForm: error message country required" data-testid="registrationform.error.Country">Country is
                                required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <mat-label i18n="registrationForm: indsutry selection label">Industry <sup>*</sup></mat-label>
                        <mat-form-field>
                            <mat-select id="industry-select" [formControl]="industry" (blur)="updateFormErrorStatus()"
                                appearance="outline" placeholder="-- Select --" i18n-placeholder="-- Select --"
                                required data-testid="registrationform.select.industry">
                                @for(industry of industries; track industry){
                                <mat-option value="{{industry}}">{{industry}}</mat-option>
                                }
                            </mat-select>
                            @if (industry.invalid) {
                            <mat-error i18n="registrationForm: error message industry selection required" data-testid="registrationform.error.industry">Industry is
                                required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">&nbsp;</div>
                </div>

                <div>
                    <mat-checkbox id="tc-checkbox" [formControl]="termsAndConditions" name="TermsAndConditions"
                        (blur)="updateFormErrorStatus()" required i18n="registrationForm: T&C checkbox label" data-testid="registrationform.checkbox.termsandconditions">
                        I hereby agree to the <a href="https://utimaco.com/terms-conditions" id="tc-check-link"
                            target="_blank" data-testid="registrationform.link.termsandconditions">Terms and Conditions</a> of the Trust as a Service portal *
                    </mat-checkbox>
                    <!-- [inputProps]="{{'data-testid': 'test-checkbox'}}" -->
                    @if (termsAndConditions.invalid) {
                    <mat-error i18n="registrationForm: error message t&cs must be accepted" data-testid="registrationform.error.termsandconditions">Please accept the Terms and
                        Conditions</mat-error>
                    }
                </div>
                <div>
                    <mat-checkbox id="data-protection-checkbox" [formControl]="dataProtectionNotice"
                        name="DataProtectionNotice" (blur)="updateFormErrorStatus()" required
                        i18n="registrationForm: data protection checkbox label" data-testid="registrationform.checkbox.dataprotectionnotice">
                        I agree with the <a href="https://utimaco.com/privacy" id="privacy-check-link"
                            target="_blank" data-testid="registrationform.link.dataprotectionnotice">Data Protection Notice</a> *
                    </mat-checkbox>
                    @if (dataProtectionNotice.invalid) {
                    <mat-error i18n="registrationForm: error message data protection notice must be accepted" data-testid="registrationform.error.dataprotectionnotice">Please
                        agree to our Data Protection Notice</mat-error>
                    }
                </div>

                <div class="dataProtectionNote">
                    <span class="bold" i18n="registrationForm: data protection block title">Data protection
                        notice:</span>&nbsp;
                    <span i18n="registrationForm: data protection explanation text">Utimaco IS GmbH collects, processes
                        and uses your
                        personal data exclusively to process your request. You can obtain information about the data
                        stored by Utimaco IS GmbH and request that your data be corrected, deleted or blocked. The
                        deletion or blocking of your data prior to completion of processing your request may prevent
                        this. You can assert these rights against Utimaco IS GmbH free of charge by sending an e-mail to
                        <a href="mailto:dataprotection@utimaco.com">dataprotection&#64;utimaco.com</a> or by notifying
                        Utimaco IS GmbH of the contact details given in the imprint. Further information on data
                        protection at Utimaco IS GmbH can be found in our <a href="https://utimaco.com/privacy"
                            id="privacy-link"> Privacy
                            Policy</a>.
                    </span>
                </div>

                <div class="formFooterActions" (mouseover)="updateFormErrorStatus(true)"
                    (focus)="updateFormErrorStatus(true)">
                    @if(formErrorPresent){
                    <div class="formError" i18n="registrationForm: footer text fill out all required fields" data-testid="registrationform.error.pleasefilloutallfields">
                        Please fill out all required fields marked with an *
                    </div>
                    }

                    <lib-taas-button [buttonText]="buttonLabelRegister" (buttonPressedEvent)="onSubmit()"
                        buttonType="highlight" addClass="fullwidth" btnId="register-now-btn" 
                        [disabled]="formErrorPresent === true || formSubmissionInProgress === true" />

                    <div class="formFooterLoginHint">
                        <span i18n="registrationForm: text for already registered user with login link">Already
                            Registered?</span>&nbsp;<a href="#" (click)="goToLoginPage()" id="login-link"
                            i18n="registrationForm: button label log-in" data-testid="registrationform.link.login">Log-in</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>