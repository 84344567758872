import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-registration-success',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './registration-success.component.html',
  styleUrl: './registration-success.component.scss'
})
export class RegistrationSuccessComponent {

  goToHomepage(){
    window.open(environment.homeLink, '_self');
  }
}
