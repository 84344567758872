import { AbstractControl, ValidatorFn } from '@angular/forms';

// Custom validator function to filter the input in the phone number field
export function CustomPhoneValidatorAddOn(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: object } | null => {
        const regExpPhone = /^[0-9\s]+$/;
        console.log("Phone validaotr", control.value, regExpPhone.test(control.value));
        const forbidden = control.value.trim() != "" && !regExpPhone.test(control.value);
        return forbidden ? { 'email': { value: control.value.trim() } } : null;
    };
}