<style>
  .gradientBackground[c-phixSM_Registration_phixSM_Registration] {
    height: 1800px;
    width: 100%;
    background: rgb(0, 41, 78);
    background: linear-gradient(180deg, rgba(0, 41, 78, 1) 0%, rgba(0, 104, 180, 1) 100%);
    font-size: 25px;
    font-weight: 300;
  }
</style>

<main class="main">
  <div class="header">
    <a [href]="homeLink" data-testid="customerregistration.header.link.utimacohomepage"><img src="assets/utimaco-logo.svg" style="height: 34px;" alt="Utimaco Logo" /></a>
    <div class="right-side-menu">
      <lib-taas-button [buttonText]="btnlabelLogIn" buttonType="highlight" id="header-login-btn" data-testid="customerregistration.header.button.login"
        (buttonPressedEvent)="goToLoginPage()"/>
      <app-language-selector data-testid="customerregistration.header.select.language"></app-language-selector>
    </div>
  </div>
  <div class="content">
    <router-outlet />
  </div>
  <div class="footer">
    <a [href]="homeLink" data-testid="customerregistration.footer.link.utimacohomepage"><img src="assets/utimaco-logo.svg" style="height: 34px;" alt="Utimaco Logo" /></a>
    <div style="display: flex; min-width: 170px;">
      <a href="https://utimaco.com/current-topics/blog/utimaco-named-leader-in-hsm-market" target="_blank">
        <img src="assets/overall-leader-large.avif" height="83" [hspace]="15" alt="Utimaco Leader in HSM" />
        <img src="assets/top-implementer-large.avif" height="83" alt="Utimaco top implementer" />
      </a>
    </div>
  </div>

  <lib-taas-hidden-version-info [appVersion]="appVersion" [commonUiLibVersion]="commonUiLibVersion" />
  <lib-taas-toastr />
</main>