[
  {
    "countryCode": "DZ",
    "countryNumber": "213",
    "displayName": "Algeria",
    "displayName_de": "Algerien"
  },
  {
    "countryCode": "AD",
    "countryNumber": "376",
    "displayName": "Andorra",
    "displayName_de": "Andorra"
  },
  {
    "countryCode": "AO",
    "countryNumber": "244",
    "displayName": "Angola",
    "displayName_de": "Angola"
  },
  {
    "countryCode": "AI",
    "countryNumber": "1264",
    "displayName": "Anguilla",
    "displayName_de": "Anguilla"
  },
  {
    "countryCode": "AG",
    "countryNumber": "1268",
    "displayName": "Antigua & Barbuda",
    "displayName_de": "Antigua & Barbuda"
  },
  {
    "countryCode": "AR",
    "countryNumber": "54",
    "displayName": "Argentina",
    "displayName_de": "Argentinien"
  },
  {
    "countryCode": "AM",
    "countryNumber": "374",
    "displayName": "Armenia",
    "displayName_de": "Armenien"
  },
  {
    "countryCode": "AW",
    "countryNumber": "297",
    "displayName": "Aruba",
    "displayName_de": "Aruba"
  },
  {
    "countryCode": "AU",
    "countryNumber": "61",
    "displayName": "Australia",
    "displayName_de": "Australien"
  },
  {
    "countryCode": "AT",
    "countryNumber": "43",
    "displayName": "Austria",
    "displayName_de": "Österreich"
  },
  {
    "countryCode": "AZ",
    "countryNumber": "994",
    "displayName": "Azerbaijan",
    "displayName_de": "Aserbaidschan"
  },
  {
    "countryCode": "BS",
    "countryNumber": "1242",
    "displayName": "Bahamas",
    "displayName_de": "Bahamas"
  },
  {
    "countryCode": "BH",
    "countryNumber": "973",
    "displayName": "Bahrain",
    "displayName_de": "Bahrain"
  },
  {
    "countryCode": "BD",
    "countryNumber": "880",
    "displayName": "Bangladesh",
    "displayName_de": "Bangladesch"
  },
  {
    "countryCode": "BB",
    "countryNumber": "1246",
    "displayName": "Barbados",
    "displayName_de": "Barbados"
  },
  {
    "countryCode": "BY",
    "countryNumber": "375",
    "displayName": "Belarus",
    "displayName_de": "Weißrussland"
  },
  {
    "countryCode": "BE",
    "countryNumber": "32",
    "displayName": "Belgium",
    "displayName_de": "Belgien"
  },
  {
    "countryCode": "BZ",
    "countryNumber": "501",
    "displayName": "Belize",
    "displayName_de": "Belize"
  },
  {
    "countryCode": "BJ",
    "countryNumber": "229",
    "displayName": "Benin",
    "displayName_de": "Benin"
  },
  {
    "countryCode": "BM",
    "countryNumber": "1441",
    "displayName": "Bermuda",
    "displayName_de": "Bermuda"
  },
  {
    "countryCode": "BT",
    "countryNumber": "975",
    "displayName": "Bhutan",
    "displayName_de": "Bhutan"
  },
  {
    "countryCode": "BO",
    "countryNumber": "591",
    "displayName": "Bolivia",
    "displayName_de": "Bolivien"
  },
  {
    "countryCode": "BA",
    "countryNumber": "387",
    "displayName": "Bosnia & Herzegovina",
    "displayName_de": "Bosnien & Herzegowina"
  },
  {
    "countryCode": "BW",
    "countryNumber": "267",
    "displayName": "Botswana",
    "displayName_de": "Botswana"
  },
  {
    "countryCode": "BR",
    "countryNumber": "55",
    "displayName": "Brazil",
    "displayName_de": "Brasilien"
  },
  {
    "countryCode": "BN",
    "countryNumber": "673",
    "displayName": "Brunei",
    "displayName_de": "Brunei"
  },
  {
    "countryCode": "BG",
    "countryNumber": "359",
    "displayName": "Bulgaria",
    "displayName_de": "Bulgarien"
  },
  {
    "countryCode": "BF",
    "countryNumber": "226",
    "displayName": "Burkina Faso",
    "displayName_de": "Burkina Faso"
  },
  {
    "countryCode": "BI",
    "countryNumber": "257",
    "displayName": "Burundi",
    "displayName_de": "Burundi"
  },
  {
    "countryCode": "KH",
    "countryNumber": "855",
    "displayName": "Cambodia",
    "displayName_de": "Kambodscha"
  },
  {
    "countryCode": "CM",
    "countryNumber": "237",
    "displayName": "Cameroon",
    "displayName_de": "Kamerun"
  },
  {
    "countryCode": "CA",
    "countryNumber": "1",
    "displayName": "Canada",
    "displayName_de": "Kanada"
  },
  {
    "countryCode": "CV",
    "countryNumber": "238",
    "displayName": "Cape Verde Islands",
    "displayName_de": "Kap Verde"
  },
  {
    "countryCode": "KY",
    "countryNumber": "1345",
    "displayName": "Cayman Islands",
    "displayName_de": "Kaimaninseln"
  },
  {
    "countryCode": "CF",
    "countryNumber": "236",
    "displayName": "Central African Republic",
    "displayName_de": "Zentralafrikanische Republik"
  },
  {
    "countryCode": "CL",
    "countryNumber": "56",
    "displayName": "Chile",
    "displayName_de": "Chile"
  },
  {
    "countryCode": "CN",
    "countryNumber": "86",
    "displayName": "China",
    "displayName_de": "China"
  },
  {
    "countryCode": "CO",
    "countryNumber": "57",
    "displayName": "Colombia",
    "displayName_de": "Kolumbien"
  },
  {
    "countryCode": "KM",
    "countryNumber": "269",
    "displayName": "Comoros",
    "displayName_de": "Komoren"
  },
  {
    "countryCode": "CG",
    "countryNumber": "242",
    "displayName": "Congo",
    "displayName_de": "Kongo"
  },
  {
    "countryCode": "CK",
    "countryNumber": "682",
    "displayName": "Cook Islands",
    "displayName_de": "Cookinseln"
  },
  {
    "countryCode": "CR",
    "countryNumber": "506",
    "displayName": "Costa Rica",
    "displayName_de": "Costa Rica"
  },
  {
    "countryCode": "HR",
    "countryNumber": "385",
    "displayName": "Croatia",
    "displayName_de": "Kroatien"
  },
  {
    "countryCode": "CU",
    "countryNumber": "53",
    "displayName": "Cuba",
    "displayName_de": "Kuba"
  },
  {
    "countryCode": "CY",
    "countryNumber": "90392",
    "displayName": "Cyprus North",
    "displayName_de": "Nordzypern"
  },
  {
    "countryCode": "CY",
    "countryNumber": "357",
    "displayName": "Cyprus South",
    "displayName_de": "Südzypern"
  },
  {
    "countryCode": "CZ",
    "countryNumber": "42",
    "displayName": "Czech Republic",
    "displayName_de": "Tschechien"
  },
  {
    "countryCode": "DK",
    "countryNumber": "45",
    "displayName": "Denmark",
    "displayName_de": "Dänemark"
  },
  {
    "countryCode": "DJ",
    "countryNumber": "253",
    "displayName": "Djibouti",
    "displayName_de": "Dschibuti"
  },
  {
    "countryCode": "DM",
    "countryNumber": "1809",
    "displayName": "Dominica",
    "displayName_de": "Dominica"
  },
  {
    "countryCode": "DO",
    "countryNumber": "1809",
    "displayName": "Dominican Republic",
    "displayName_de": "Dominikanische Republik"
  },
  {
    "countryCode": "EC",
    "countryNumber": "593",
    "displayName": "Ecuador",
    "displayName_de": "Ecuador"
  },
  {
    "countryCode": "EG",
    "countryNumber": "20",
    "displayName": "Egypt",
    "displayName_de": "Ägypten"
  },
  {
    "countryCode": "SV",
    "countryNumber": "503",
    "displayName": "El Salvador",
    "displayName_de": "El Salvador"
  },
  {
    "countryCode": "GQ",
    "countryNumber": "240",
    "displayName": "Equatorial Guinea",
    "displayName_de": "Äquatorialguinea"
  },
  {
    "countryCode": "ER",
    "countryNumber": "291",
    "displayName": "Eritrea",
    "displayName_de": "Eritrea"
  },
  {
    "countryCode": "EE",
    "countryNumber": "372",
    "displayName": "Estonia",
    "displayName_de": "Estland"
  },
  {
    "countryCode": "ET",
    "countryNumber": "251",
    "displayName": "Ethiopia",
    "displayName_de": "Äthiopien"
  },
  {
    "countryCode": "FK",
    "countryNumber": "500",
    "displayName": "Falkland Islands",
    "displayName_de": "Falklandinseln"
  },
  {
    "countryCode": "FO",
    "countryNumber": "298",
    "displayName": "Faroe Islands",
    "displayName_de": "Färöer-Inseln"
  },
  {
    "countryCode": "FJ",
    "countryNumber": "679",
    "displayName": "Fiji",
    "displayName_de": "Fidschi"
  },
  {
    "countryCode": "FI",
    "countryNumber": "358",
    "displayName": "Finland",
    "displayName_de": "Finnland"
  },
  {
    "countryCode": "FR",
    "countryNumber": "33",
    "displayName": "France",
    "displayName_de": "Frankreich"
  },
  {
    "countryCode": "GF",
    "countryNumber": "594",
    "displayName": "French Guiana",
    "displayName_de": "Französisch-Guayana"
  },
  {
    "countryCode": "PF",
    "countryNumber": "689",
    "displayName": "French Polynesia",
    "displayName_de": "Französisch-Polynesien"
  },
  {
    "countryCode": "GA",
    "countryNumber": "241",
    "displayName": "Gabon",
    "displayName_de": "Gabun"
  },
  {
    "countryCode": "GM",
    "countryNumber": "220",
    "displayName": "Gambia",
    "displayName_de": "Gambia"
  },
  {
    "countryCode": "GE",
    "countryNumber": "7880",
    "displayName": "Georgia",
    "displayName_de": "Georgien"
  },
  {
    "countryCode": "DE",
    "countryNumber": "49",
    "displayName": "Germany",
    "displayName_de": "Deutschland"
  },
  {
    "countryCode": "GH",
    "countryNumber": "233",
    "displayName": "Ghana",
    "displayName_de": "Ghana"
  },
  {
    "countryCode": "GI",
    "countryNumber": "350",
    "displayName": "Gibraltar",
    "displayName_de": "Gibraltar"
  },
  {
    "countryCode": "GR",
    "countryNumber": "30",
    "displayName": "Greece",
    "displayName_de": "Griechenland"
  },
  {
    "countryCode": "GL",
    "countryNumber": "299",
    "displayName": "Greenland",
    "displayName_de": "Grönland"
  },
  {
    "countryCode": "GD",
    "countryNumber": "1473",
    "displayName": "Grenada",
    "displayName_de": "Grenada"
  },
  {
    "countryCode": "GP",
    "countryNumber": "590",
    "displayName": "Guadeloupe",
    "displayName_de": "Guadeloupe"
  },
  {
    "countryCode": "GU",
    "countryNumber": "671",
    "displayName": "Guam",
    "displayName_de": "Guam"
  },
  {
    "countryCode": "GT",
    "countryNumber": "502",
    "displayName": "Guatemala",
    "displayName_de": "Guatemala"
  },
  {
    "countryCode": "GN",
    "countryNumber": "224",
    "displayName": "Guinea",
    "displayName_de": "Guinea"
  },
  {
    "countryCode": "GW",
    "countryNumber": "245",
    "displayName": "Guinea - Bissau",
    "displayName_de": "Guinea-Bissau"
  },
  {
    "countryCode": "GY",
    "countryNumber": "592",
    "displayName": "Guyana",
    "displayName_de": "Guyana"
  },
  {
    "countryCode": "HT",
    "countryNumber": "509",
    "displayName": "Haiti",
    "displayName_de": "Haiti"
  },
  {
    "countryCode": "HN",
    "countryNumber": "504",
    "displayName": "Honduras",
    "displayName_de": "Honduras"
  },
  {
    "countryCode": "HK",
    "countryNumber": "852",
    "displayName": "Hong Kong",
    "displayName_de": "Hongkong"
  },
  {
    "countryCode": "HU",
    "countryNumber": "36",
    "displayName": "Hungary",
    "displayName_de": "Ungarn"
  },
  {
    "countryCode": "IS",
    "countryNumber": "354",
    "displayName": "Iceland",
    "displayName_de": "Island"
  },
  {
    "countryCode": "IN",
    "countryNumber": "91",
    "displayName": "India",
    "displayName_de": "Indien"
  },
  {
    "countryCode": "ID",
    "countryNumber": "62",
    "displayName": "Indonesia",
    "displayName_de": "Indonesien"
  },
  {
    "countryCode": "IR",
    "countryNumber": "98",
    "displayName": "Iran",
    "displayName_de": "Iran"
  },
  {
    "countryCode": "IQ",
    "countryNumber": "964",
    "displayName": "Iraq",
    "displayName_de": "Irak"
  },
  {
    "countryCode": "IE",
    "countryNumber": "353",
    "displayName": "Ireland",
    "displayName_de": "Irland"
  },
  {
    "countryCode": "IL",
    "countryNumber": "972",
    "displayName": "Israel",
    "displayName_de": "Israel"
  },
  {
    "countryCode": "IT",
    "countryNumber": "39",
    "displayName": "Italy",
    "displayName_de": "Italien"
  },
  {
    "countryCode": "JM",
    "countryNumber": "1876",
    "displayName": "Jamaica",
    "displayName_de": "Jamaika"
  },
  {
    "countryCode": "JP",
    "countryNumber": "81",
    "displayName": "Japan",
    "displayName_de": "Japan"
  },
  {
    "countryCode": "JO",
    "countryNumber": "962",
    "displayName": "Jordan",
    "displayName_de": "Jordanien"
  },
  {
    "countryCode": "KZ",
    "countryNumber": "7",
    "displayName": "Kazakhstan",
    "displayName_de": "Kasachstan"
  },
  {
    "countryCode": "KE",
    "countryNumber": "254",
    "displayName": "Kenya",
    "displayName_de": "Kenia"
  },
  {
    "countryCode": "KI",
    "countryNumber": "686",
    "displayName": "Kiribati",
    "displayName_de": "Kiribati"
  },
  {
    "countryCode": "KP",
    "countryNumber": "850",
    "displayName": "Korea North",
    "displayName_de": "Nordkorea"
  },
  {
    "countryCode": "KR",
    "countryNumber": "82",
    "displayName": "Korea South",
    "displayName_de": "Südkorea"
  },
  {
    "countryCode": "KW",
    "countryNumber": "965",
    "displayName": "Kuwait",
    "displayName_de": "Kuwait"
  },
  {
    "countryCode": "KG",
    "countryNumber": "996",
    "displayName": "Kyrgyzstan",
    "displayName_de": "Kirgisistan"
  },
  {
    "countryCode": "LA",
    "countryNumber": "856",
    "displayName": "Laos",
    "displayName_de": "Laos"
  },
  {
    "countryCode": "LV",
    "countryNumber": "371",
    "displayName": "Latvia",
    "displayName_de": "Lettland"
  },
  {
    "countryCode": "LB",
    "countryNumber": "961",
    "displayName": "Lebanon",
    "displayName_de": "Libanon"
  },
  {
    "countryCode": "LS",
    "countryNumber": "266",
    "displayName": "Lesotho",
    "displayName_de": "Lesotho"
  },
  {
    "countryCode": "LR",
    "countryNumber": "231",
    "displayName": "Liberia",
    "displayName_de": "Liberia"
  },
  {
    "countryCode": "LY",
    "countryNumber": "218",
    "displayName": "Libya",
    "displayName_de": "Libyen"
  },
  {
    "countryCode": "LI",
    "countryNumber": "417",
    "displayName": "Liechtenstein",
    "displayName_de": "Liechtenstein"
  },
  {
    "countryCode": "LT",
    "countryNumber": "370",
    "displayName": "Lithuania",
    "displayName_de": "Litauen"
  },
  {
    "countryCode": "LU",
    "countryNumber": "352",
    "displayName": "Luxembourg",
    "displayName_de": "Luxemburg"
  },
  {
    "countryCode": "MO",
    "countryNumber": "853",
    "displayName": "Macao",
    "displayName_de": "Macao"
  },
  {
    "countryCode": "MK",
    "countryNumber": "389",
    "displayName": "Macedonia",
    "displayName_de": "Mazedonien"
  },
  {
    "countryCode": "MG",
    "countryNumber": "261",
    "displayName": "Madagascar",
    "displayName_de": "Madagaskar"
  },
  {
    "countryCode": "MW",
    "countryNumber": "265",
    "displayName": "Malawi",
    "displayName_de": "Malawi"
  },
  {
    "countryCode": "MY",
    "countryNumber": "60",
    "displayName": "Malaysia",
    "displayName_de": "Malaysia"
  },
  {
    "countryCode": "MV",
    "countryNumber": "960",
    "displayName": "Maldives",
    "displayName_de": "Malediven"
  },
  {
    "countryCode": "ML",
    "countryNumber": "223",
    "displayName": "Mali",
    "displayName_de": "Mali"
  },
  {
    "countryCode": "MT",
    "countryNumber": "356",
    "displayName": "Malta",
    "displayName_de": "Malta"
  },
  {
    "countryCode": "MH",
    "countryNumber": "692",
    "displayName": "Marshall Islands",
    "displayName_de": "Marshallinseln"
  },
  {
    "countryCode": "MQ",
    "countryNumber": "596",
    "displayName": "Martinique",
    "displayName_de": "Martinique"
  },
  {
    "countryCode": "MR",
    "countryNumber": "222",
    "displayName": "Mauritania",
    "displayName_de": "Mauretanien"
  },
  {
    "countryCode": "YT",
    "countryNumber": "269",
    "displayName": "Mayotte",
    "displayName_de": "Mayotte"
  },
  {
    "countryCode": "MX",
    "countryNumber": "52",
    "displayName": "Mexico",
    "displayName_de": "Mexiko"
  },
  {
    "countryCode": "FM",
    "countryNumber": "691",
    "displayName": "Micronesia",
    "displayName_de": "Mikronesien"
  },
  {
    "countryCode": "MD",
    "countryNumber": "373",
    "displayName": "Moldova",
    "displayName_de": "Moldawien"
  },
  {
    "countryCode": "MC",
    "countryNumber": "377",
    "displayName": "Monaco",
    "displayName_de": "Monaco"
  },
  {
    "countryCode": "MN",
    "countryNumber": "976",
    "displayName": "Mongolia",
    "displayName_de": "Mongolei"
  },
  {
    "countryCode": "MS",
    "countryNumber": "1664",
    "displayName": "Montserrat",
    "displayName_de": "Montserrat"
  },
  {
    "countryCode": "MA",
    "countryNumber": "212",
    "displayName": "Morocco",
    "displayName_de": "Marokko"
  },
  {
    "countryCode": "MZ",
    "countryNumber": "258",
    "displayName": "Mozambique",
    "displayName_de": "Mosambik"
  },
  {
    "countryCode": "MN",
    "countryNumber": "95",
    "displayName": "Myanmar",
    "displayName_de": "Myanmar"
  },
  {
    "countryCode": "NA",
    "countryNumber": "264",
    "displayName": "Namibia",
    "displayName_de": "Namibia"
  },
  {
    "countryCode": "NR",
    "countryNumber": "674",
    "displayName": "Nauru",
    "displayName_de": "Nauru"
  },
  {
    "countryCode": "NP",
    "countryNumber": "977",
    "displayName": "Nepal",
    "displayName_de": "Nepal"
  },
  {
    "countryCode": "NL",
    "countryNumber": "31",
    "displayName": "Netherlands",
    "displayName_de": "Niederlande"
  },
  {
    "countryCode": "AN",
    "countryNumber": "599",
    "displayName": "Netherlands Antilles",
    "displayName_de": "Niederländische Antillen"
  },
  {
    "countryCode": "NC",
    "countryNumber": "687",
    "displayName": "New Caledonia",
    "displayName_de": "Neukaledonien"
  },
  {
    "countryCode": "NZ",
    "countryNumber": "64",
    "displayName": "New Zealand",
    "displayName_de": "Neuseeland"
  },
  {
    "countryCode": "NI",
    "countryNumber": "505",
    "displayName": "Nicaragua",
    "displayName_de": "Nicaragua"
  },
  {
    "countryCode": "NE",
    "countryNumber": "227",
    "displayName": "Niger",
    "displayName_de": "Niger"
  },
  {
    "countryCode": "NG",
    "countryNumber": "234",
    "displayName": "Nigeria",
    "displayName_de": "Nigeria"
  },
  {
    "countryCode": "NU",
    "countryNumber": "683",
    "displayName": "Niue",
    "displayName_de": "Niue"
  },
  {
    "countryCode": "NF",
    "countryNumber": "672",
    "displayName": "Norfolk Islands",
    "displayName_de": "Norfolkinseln"
  },
  {
    "countryCode": "NP",
    "countryNumber": "670",
    "displayName": "Northern Marianas",
    "displayName_de": "Nördliche Marianen"
  },
  {
    "countryCode": "NO",
    "countryNumber": "47",
    "displayName": "Norway",
    "displayName_de": "Norwegen"
  },
  {
    "countryCode": "OM",
    "countryNumber": "968",
    "displayName": "Oman",
    "displayName_de": "Oman"
  },
  {
    "countryCode": "PW",
    "countryNumber": "680",
    "displayName": "Palau",
    "displayName_de": "Palau"
  },
  {
    "countryCode": "PA",
    "countryNumber": "507",
    "displayName": "Panama",
    "displayName_de": "Panama"
  },
  {
    "countryCode": "PG",
    "countryNumber": "675",
    "displayName": "Papua New Guinea",
    "displayName_de": "Papua-Neuguinea"
  },
  {
    "countryCode": "PY",
    "countryNumber": "595",
    "displayName": "Paraguay",
    "displayName_de": "Paraguay"
  },
  {
    "countryCode": "PE",
    "countryNumber": "51",
    "displayName": "Peru",
    "displayName_de": "Peru"
  },
  {
    "countryCode": "PH",
    "countryNumber": "63",
    "displayName": "Philippines",
    "displayName_de": "Philippinen"
  },
  {
    "countryCode": "PL",
    "countryNumber": "48",
    "displayName": "Poland",
    "displayName_de": "Polen"
  },
  {
    "countryCode": "PT",
    "countryNumber": "351",
    "displayName": "Portugal",
    "displayName_de": "Portugal"
  },
  {
    "countryCode": "PR",
    "countryNumber": "1787",
    "displayName": "Puerto Rico",
    "displayName_de": "Puerto Rico"
  },
  {
    "countryCode": "QA",
    "countryNumber": "974",
    "displayName": "Qatar",
    "displayName_de": "Katar"
  },
  {
    "countryCode": "RE",
    "countryNumber": "262",
    "displayName": "Reunion",
    "displayName_de": "Reunion"
  },
  {
    "countryCode": "RO",
    "countryNumber": "40",
    "displayName": "Romania",
    "displayName_de": "Rumänien"
  },
  {
    "countryCode": "RU",
    "countryNumber": "7",
    "displayName": "Russia",
    "displayName_de": "Russland"
  },
  {
    "countryCode": "RW",
    "countryNumber": "250",
    "displayName": "Rwanda",
    "displayName_de": "Ruanda"
  },
  {
    "countryCode": "SM",
    "countryNumber": "378",
    "displayName": "San Marino",
    "displayName_de": "San Marino"
  },
  {
    "countryCode": "ST",
    "countryNumber": "239",
    "displayName": "Sao Tome & Principe",
    "displayName_de": "Sao Tome & Principe"
  },
  {
    "countryCode": "SA",
    "countryNumber": "966",
    "displayName": "Saudi Arabia",
    "displayName_de": "Saudi-Arabien"
  },
  {
    "countryCode": "SN",
    "countryNumber": "221",
    "displayName": "Senegal",
    "displayName_de": "Senegal"
  },
  {
    "countryCode": "SC",
    "countryNumber": "248",
    "displayName": "Seychelles",
    "displayName_de": "Seychellen"
  },
  {
    "countryCode": "SL",
    "countryNumber": "232",
    "displayName": "Sierra Leone",
    "displayName_de": "Sierra Leone"
  },
  {
    "countryCode": "SG",
    "countryNumber": "65",
    "displayName": "Singapore",
    "displayName_de": "Singapur"
  },
  {
    "countryCode": "SK",
    "countryNumber": "421",
    "displayName": "Slovakia",
    "displayName_de": "Slowakei"
  },
  {
    "countryCode": "SI",
    "countryNumber": "386",
    "displayName": "Slovenia",
    "displayName_de": "Slowenien"
  },
  {
    "countryCode": "SB",
    "countryNumber": "677",
    "displayName": "Solomon Islands",
    "displayName_de": "Salomonen"
  },
  {
    "countryCode": "SO",
    "countryNumber": "252",
    "displayName": "Somalia",
    "displayName_de": "Somalia"
  },
  {
    "countryCode": "ZA",
    "countryNumber": "27",
    "displayName": "South Africa",
    "displayName_de": "Südafrika"
  },
  {
    "countryCode": "ES",
    "countryNumber": "34",
    "displayName": "Spain",
    "displayName_de": "Spanien"
  },
  {
    "countryCode": "LK",
    "countryNumber": "94",
    "displayName": "Sri Lanka",
    "displayName_de": "Sri Lanka"
  },
  {
    "countryCode": "SH",
    "countryNumber": "290",
    "displayName": "St. Helena",
    "displayName_de": "St. Helena"
  },
  {
    "countryCode": "KN",
    "countryNumber": "1869",
    "displayName": "St. Kitts",
    "displayName_de": "St. Kitts"
  },
  {
    "countryCode": "SC",
    "countryNumber": "1758",
    "displayName": "St. Lucia",
    "displayName_de": "St. Lucia"
  },
  {
    "countryCode": "SR",
    "countryNumber": "597",
    "displayName": "Suriname",
    "displayName_de": "Suriname"
  },
  {
    "countryCode": "SZ",
    "countryNumber": "268",
    "displayName": "Swaziland",
    "displayName_de": "Swasiland"
  },
  {
    "countryCode": "SE",
    "countryNumber": "46",
    "displayName": "Sweden",
    "displayName_de": "Schweden"
  },
  {
    "countryCode": "CH",
    "countryNumber": "41",
    "displayName": "Switzerland",
    "displayName_de": "Schweiz"
  },
  {
    "countryCode": "SY",
    "countryNumber": "963",
    "displayName": "Syria",
    "displayName_de": "Syrien"
  },
  {
    "countryCode": "TW",
    "countryNumber": "886",
    "displayName": "Taiwan",
    "displayName_de": "Taiwan"
  },
  {
    "countryCode": "TJ",
    "countryNumber": "7",
    "displayName": "Tajikstan",
    "displayName_de": "Tadschikistan"
  },
  {
    "countryCode": "TH",
    "countryNumber": "66",
    "displayName": "Thailand",
    "displayName_de": "Thailand"
  },
  {
    "countryCode": "TG",
    "countryNumber": "228",
    "displayName": "Togo",
    "displayName_de": "Togo"
  },
  {
    "countryCode": "TO",
    "countryNumber": "676",
    "displayName": "Tonga",
    "displayName_de": "Tonga"
  },
  {
    "countryCode": "TT",
    "countryNumber": "1868",
    "displayName": "Trinidad & Tobago",
    "displayName_de": "Trinidad & Tobago"
  },
  {
    "countryCode": "TN",
    "countryNumber": "216",
    "displayName": "Tunisia",
    "displayName_de": "Tunesien"
  },
  {
    "countryCode": "TR",
    "countryNumber": "90",
    "displayName": "Türkiye",
    "displayName_de": "Türkei"
  },
  {
    "countryCode": "TM",
    "countryNumber": "993",
    "displayName": "Turkmenistan",
    "displayName_de": "Turkmenistan"
  },
  {
    "countryCode": "TV",
    "countryNumber": "688",
    "displayName": "Tuvalu",
    "displayName_de": "Tuvalu"
  },
  {
    "countryCode": "UG",
    "countryNumber": "256",
    "displayName": "Uganda",
    "displayName_de": "Uganda"
  },
  {
    "countryCode": "UA",
    "countryNumber": "380",
    "displayName": "Ukraine",
    "displayName_de": "Ukraine"
  },
  {
    "countryCode": "AE",
    "countryNumber": "971",
    "displayName": "United Arab Emirates",
    "displayName_de": "Vereinigte Arabische Emirate"
  },
  {
    "countryCode": "GB",
    "countryNumber": "44",
    "displayName": "United Kingdom",
    "displayName_de": "Vereinigtes Königreich"
  },
  {
    "countryCode": "US",
    "countryNumber": "1",
    "displayName": "United States of America",
    "displayName_de": "Vereinigte Staaten von Amerika"
  },
  {
    "countryCode": "UY",
    "countryNumber": "598",
    "displayName": "Uruguay",
    "displayName_de": "Uruguay"
  },
  {
    "countryCode": "UZ",
    "countryNumber": "7",
    "displayName": "Uzbekistan",
    "displayName_de": "Usbekistan"
  },
  {
    "countryCode": "VU",
    "countryNumber": "678",
    "displayName": "Vanuatu",
    "displayName_de": "Vanuatu"
  },
  {
    "countryCode": "VA",
    "countryNumber": "379",
    "displayName": "Vatican City",
    "displayName_de": "Vatikanstadt"
  },
  {
    "countryCode": "VE",
    "countryNumber": "58",
    "displayName": "Venezuela",
    "displayName_de": "Venezuela"
  },
  {
    "countryCode": "VN",
    "countryNumber": "84",
    "displayName": "Vietnam",
    "displayName_de": "Vietnam"
  },
  {
    "countryCode": "VG",
    "countryNumber": "1284",
    "displayName": "Virgin Islands (UK)",
    "displayName_de": "Jungferninseln (UK)"
  },
  {
    "countryCode": "VI",
    "countryNumber": "1340",
    "displayName": "Virgin Islands (USA)",
    "displayName_de": "Jungferninseln (USA)"
  },
  {
    "countryCode": "EH",
    "countryNumber": "685",
    "displayName": "Western Samoa",
    "displayName_de": "West-Samoa"
  },
  {
    "countryCode": "YE",
    "countryNumber": "967",
    "displayName": "Yemen",
    "displayName_de": "Jemen"
  },
  {
    "countryCode": "ZM",
    "countryNumber": "260",
    "displayName": "Zambia",
    "displayName_de": "Sambia"
  },
  {
    "countryCode": "ZW",
    "countryNumber": "263",
    "displayName": "Zimbabwe",
    "displayName_de": "Simbabwe"
  }
]