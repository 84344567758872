import { Inject, Component, LOCALE_ID } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [MatSelectModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss'
})
export class LanguageSelectorComponent {

  locales = [
    { code: 'en', name: 'English' },
    { code: 'de', name: 'Deutsch' },
    { code: 'sv', name: 'Svenska' },
    { code: 'es', name: 'Español' },
  ];

  readonly selectedLocale = new FormControl('');

  constructor(@Inject(LOCALE_ID) public activeLocale: string) {
    this.selectedLocale.setValue(activeLocale);
  }

  onLanguageSelect() {
    window.location.href = `/${this.selectedLocale.getRawValue()}/index.html`;
  }
}


