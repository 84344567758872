<div id="registration-form-component" class="gradientBackground">
    <div id="registration-form-container">
        <div i18n="registrationForm: title" class="registrationTitle">Registration</div>
        <h3 i18n="registrationForm: subtitle" class="registrationSubTitle">Achieve digital trust for your business within few clicks.</h3>

        <div id="registration-form">
            @if(submissionError){
            <div id="submission-error" i18n="registrationForm: form submission error message">
                We are sorry, a problem occured while trying to submit your registration details.<br />Please try again
                or or reach out to us via our <a href="https://utimaco.com/company/contact-us" target="_blank">contact
                    form</a>.
            </div>
            }

            <form (ngSubmit)="onSubmit()" method="POST" id="registrationForm">
                <p i18n="registrationForm: form intro text">Please fill out the form to achieve digital trust for your business. Driven by yourself –
                    powered by
                    Utimaco.</p>
                <div class="row" style="margin-top: 30px;">
                    <div class="col">
                        <mat-label i18n="registrationForm: firstname field label">First-Name <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="firstname-input" name="FirstName" [formControl]="firstName"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="60">
                            @if (firstName.invalid) {
                            <mat-error i18n="registrationForm: error message firstname required">First-Name is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: lastname field label">Last-Name <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="lastname-input" name="LastName" [formControl]="lastName"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="60">
                            @if (lastName.invalid) {
                            <mat-error i18n="registrationForm: error message lastname required">Last-Name is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-label i18n="registrationForm: Email field label">Email <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="email-input" type="email" name="Email" [formControl]="email"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="200">
                            @if (email.invalid) {
                            <mat-error i18n="registrationForm: error message valid email required">Valid Email address is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: email confirmation field label">Confirm Email <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="email-confirmation-input" type="email" [formControl]="emailConfirmation"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="200">
                            @if (emailConfirmation.invalid) {
                            <mat-error i18n="registrationForm: error message emails must match">Email addresses must match</mat-error>
                            }
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <mat-label i18n="registrationForm: phone country code selection label">Country Code</mat-label>
                        <mat-form-field>
                            <mat-select id="country-code-input" [formControl]="phoneCountryCode"
                                (blur)="updateFormErrorStatus()" appearance="outline" placeholder="-- Select --"
                                i18n-placeholder="-- Select --">
                                @for(countryCodeObject of countryCodeList; track countryCodeObject.countryNumber){
                                <mat-option value="{{countryCodeObject.countryNumber}}">
                                    {{ getLocalCountryName(countryCodeObject) }} (+{{countryCodeObject.countryNumber}})
                                </mat-option>
                                }
                            </mat-select>
                            @if (phoneCountryCode.invalid) {
                            <mat-error i18n="registrationForm: error message country code required">Country Code is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: phone number label">Phone Number</mat-label>
                        <mat-form-field appearance="outline">
                            <input id="phone-number-input" [formControl]="phoneNumber" (blur)="updateFormErrorStatus()"
                                matInput maxlength="60">
                            @if (phoneNumber.invalid) {
                            <mat-error i18n="registrationForm: error message valid phone number required">A valid phone number is required. Only numbers and spaces are
                                allowed.</mat-error>
                            }
                        </mat-form-field>
                        <input type="hidden" name="Phone" [formControl]="phone" maxlength="50" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-label i18n="registrationForm: company label">Company <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="company-input" [formControl]="company" name="Company"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="200">
                            @if (company.invalid) {
                            <mat-error i18n="registrationForm: error message company required">Company is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: street address label">Street Address <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="street-input" [formControl]="address" name="Address"
                                (blur)="updateFormErrorStatus()" matInput required maxlength="200">
                            @if (address.invalid) {
                            <mat-error i18n="registrationForm: error message valid street address required">Street Address is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-label i18n="registrationForm: zip code label">ZIP</mat-label>
                        <mat-form-field appearance="outline">
                            <input id="zip-input" [formControl]="zip" name="ZIP" (blur)="updateFormErrorStatus()"
                                matInput maxlength="15">
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: label for City">City <sup>*</sup></mat-label>
                        <mat-form-field appearance="outline">
                            <input id="city-input" [formControl]="city" name="City" (blur)="updateFormErrorStatus()"
                                matInput required maxlength="60">
                            @if (city.invalid) {
                            <mat-error i18n="registrationForm: error message valid city required">City is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-label i18n="registrationForm: label for Country selection">Country <sup>*</sup></mat-label>
                        <mat-form-field>
                            <mat-select id="country-select" [formControl]="country" (blur)="updateFormErrorStatus()"
                                appearance="outline" placeholder="-- Select --" i18n-placeholder="-- Select --"
                                required>
                                @for(countryCodeObject of countryCodeList; track countryCodeObject.countryCode){
                                <mat-option value="{{countryCodeObject.countryCode}}">
                                    {{getLocalCountryName(countryCodeObject) }}
                                </mat-option>
                                }

                            </mat-select>
                            <input type="hidden" name="Country" [formControl]="countryHidden" />
                            @if (country.invalid) {
                            <mat-error i18n="registrationForm: error message country required">Country is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <mat-label i18n="registrationForm: indsutry selection label">Industry <sup>*</sup></mat-label>
                        <mat-form-field>
                            <mat-select id="industry-select" [formControl]="industry" (blur)="updateFormErrorStatus()"
                                appearance="outline" placeholder="-- Select --" i18n-placeholder="-- Select --"
                                required>
                                @for(industry of industries; track industry){
                                <mat-option value="{{industry}}">{{industry}}</mat-option>
                                }
                            </mat-select>
                            <input type="hidden" name="Industry" [formControl]="industryHidden" />
                            @if (industry.invalid) {
                            <mat-error i18n="registrationForm: error message industry selection required">Industry is required</mat-error>
                            }
                        </mat-form-field>
                    </div>
                    <div class="col">&nbsp;</div>
                </div>

                <div>
                    <mat-checkbox id="tc-checkbox" [formControl]="termsAndConditions" name="TermsAndConditions"
                        (blur)="updateFormErrorStatus()" required i18n="registrationForm: T&C checkbox label">
                        I hereby agree to the <a href="https://utimaco.com/terms-conditions" id="tc-check-link"
                            target="_blank">Terms and Conditions</a> of the Trust as a Service portal *
                    </mat-checkbox>
                    @if (termsAndConditions.invalid) {
                    <mat-error i18n="registrationForm: error message t&cs must be accepted">Please accept the Terms and Conditions</mat-error>
                    }
                </div>
                <div>
                    <mat-checkbox id="data-protection-checkbox" [formControl]="dataProtectionNotice"
                        name="DataProtectionNotice" (blur)="updateFormErrorStatus()" required i18n="registrationForm: data protection checkbox label">
                        I agree with the <a href="https://utimaco.com/privacy" id="privacy-check-link"
                            target="_blank">Data Protection Notice</a> *
                    </mat-checkbox>
                    @if (dataProtectionNotice.invalid) {
                    <mat-error i18n="registrationForm: error message data protection notice must be accepted">Please agree to our Data Protection Notice</mat-error>
                    }
                </div>

                <div class="dataProtectionNote">
                    <span class="bold" i18n="registrationForm: data protection block title">Data protection notice:</span>&nbsp;
                    <span i18n="registrationForm: data protection explanation text">Utimaco IS GmbH collects, processes and uses your
                        personal data exclusively to process your request. You can obtain information about the data
                        stored by Utimaco IS GmbH and request that your data be corrected, deleted or blocked. The
                        deletion or blocking of your data prior to completion of processing your request may prevent
                        this. You can assert these rights against Utimaco IS GmbH free of charge by sending an e-mail to
                        <a href="mailto:dataprotection@utimaco.com">dataprotection&#64;utimaco.com</a> or by notifying
                        Utimaco IS GmbH of the contact details given in the imprint. Further information on data
                        protection at Utimaco IS GmbH can be found in our <a href="https://utimaco.com/privacy"
                            id="privacy-link"> Privacy
                            Policy</a>.
                    </span>
                </div>

                <div class="formFooterActions" (mouseover)="updateFormErrorStatus(true)"
                    (focus)="updateFormErrorStatus(true)">
                    @if(formErrorPresent){
                    <div class="formError" i18n="registrationForm: footer text fill out all required fields">
                        Please fill out all required fields marked with an *
                    </div>
                    }

                    <button mat-raised-button type="submit" id="register-now-btn" color="accent" style="width:100%"
                        [disabled]="formErrorPresent === true" i18n="registrationForm:button label register now">Register
                        Now</button>

                    <div>
                        <span i18n="registrationForm: text for already registered user with login link">Already Registered?</span>&nbsp;<a href="#" (click)="goToLoginPage()" id="login-link"
                            i18n="registrationForm: button label log-in">Log-in</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>